<template>
	<div class="main">
    <TheTopbar/>
    
    <button class="btn btn-secondary" @click="goBack()">back <b-badge class="text-dark" v-b-tooltip.hover title="Click to go back to the previous page">i</b-badge></button>
    <slot />
    <div class="main-container">
      <div class="d-flex">
        <div class="content-wrapper">
          <Header></Header>
              <div class="options">
                        
                        <button class="btn btn-main " @click="goToProfile()" :disabled="!init.FXCUSTVIEW">AUTHORIZED REP</button>
                        <button class="btn btn-main " @click="goToCompany()" :disabled="!init.FXCUSTVIEW">COMPANY</button>
                        <button class="btn btn-main " @click="goToApplication()" :disabled="!init.FXCUSTAPPLICATION">APPLICATION</button>
                        <!-- <button class="btn btn-main " @click="goToUsers()" :disabled="!init.FXCUSTUSERS">USERS</button> -->
                        <button class="btn btn-main " @click="goToTransactions()" :disabled="!init.FXCUSTTRANSACTIONS">TRANSACTIONS</button>
                        <button class="btn btn-main active" >ACTIVITY LOGS</button>
                        
                </div>
                 

            <div class="market-content">
              <label>ACTIVITY</label>
              <table class="table">
                  <thead>
                    <tr>
                          <th>Activity ID</th>
                          <th>Date</th>
                          <th>Category</th>
                          <th>Description</th>
                          <th>IP Address</th>
                    </tr>
                  </thead>
                  
                  <tbody>
                    <tr v-if="sortedActivity.length == 0">
                      <td colspan="10">No Activity found</td>
                     
                    </tr>
                    <tr v-for="activity in sortedActivity" :key="activity.id">
                        <td>{{ activity.activityId }}</td>
                        <td>{{ formatDate(activity.activityDate) }}</td>
                        <td>{{ activity.category }}</td>
                        <td>{{ activity.description }}</td>
                        <td>{{ activity.ipAddress }}</td>
                    </tr>
                  </tbody>
                </table>
                <!-- <div v-if="errors.show">
                  <p style="color: lightgray;">{{errors.msg}}</p>
                </div> -->
                <div style="float: right !important;">
                    <button @click="prevPageActivity" :disabled="currentPageActivity === 1" class="btn btn-light">Previous</button>
                    <span class="px-2 text-white"> {{ currentPageActivity }} </span>
                    <button @click="nextPageActivity" :disabled="currentPageActivity === totalPagesActivity" class="btn btn-light">Next</button>
                </div>
                
            </div>


            <div class="market-content">
            
              <h4 class="text-white">INTEGRATION LOGS</h4> 
              <div v-if="errors.show">
                <h5 style="color: lightgray;">{{errors.msg}}</h5>
               </div>
              <table class="table">
                  <thead>
                    <tr>
                          <th>Alert ID</th>
                          <th>Host</th>
                          <th>Date</th>
                          <th>Entity ID</th>
                          <th>Category</th>
                          <th>Description</th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th>Action</th>
                    </tr>
                  </thead>
                  
                  <tbody>
                    <tr v-if="sortedAlerts.length == 0">
                      <td colspan="10">No Logs found</td>
                     
                    </tr>
                    <tr v-for="alert in paginatedData" :key="alert.id">
                        <td>{{ alert.alertId }}</td>
                        <td>{{ alert.alertDate }}</td>
                        <td>{{ formatDate(alert.alertDate) }}</td>
                        <td>{{ alert.entityId }}</td>
                        <td>{{ alert.category }}</td>
                        <td>{{ alert.alertText }}</td>
                        <td >
                          {{alert.resolution}}
                        </td>
                        <td>
                          {{alert.resolvedBy}}
                        </td>
                        <td>
                          {{alert.resolvedDate}}
                        </td>
                        <td >
                          <button class="btn btn-light" @click="openResolve(alert)" v-if="alert.resolved == false">Resolve</button>
                        </td>
                      </tr>
                  </tbody>
                </table>
                <div style="float: right !important;">
                    <button @click="prevPage" :disabled="currentPage === 1" class="btn btn-light">Previous</button>
                    <span class="px-2 text-white"> {{ currentPage }} </span>
                    <button @click="nextPage" :disabled="currentPage === totalPages" class="btn btn-light">Next</button>
                </div>
            
                
            </div>

                

        </div>

        <ResolveLogs v-if="showResolve" @close="openResolve(alert)" :alert="alert" ></ResolveLogs>
        </div>
      </div>
	</div>
</template>
        
<script>
  import moment from 'moment';
  import TheTopbar from '../components/TheTopbar.vue'
  import Approve from '../components/modal/Approve.vue'
  import Header from '../components/Header.vue'
  import ResolveLogs from '../components/modal/Documents/ResolveLogs.vue';
 

  export default {
      name: 'Users',
      components: {
          TheTopbar,
          Approve,
          Header,
          ResolveLogs
      },
      
       data: function() {
        const today = new Date();
        return { 
          errors: {
                msg: "",
                show: false
              
            },
          activityLogs:[],
          alertLogs:[],
          showResolve: false,
          alert:{},
          itemsPerPageActivity:5,
          currentPageActivity:1,
          itemsPerPage: 5,
          currentPage: 1,
          merchantId:'',
          integrationLogs:[]
            
        
        
        }
        },
      computed:{
        show() {
          return this.$store.state.show;
        },
        init() {
            return this.$store.state.init;
        },
        sortedActivity() {
          return this.activityLogs.slice().sort((a, b) => a.alertId - b.alertId);
        },
        totalPagesActivity() {
          return Math.ceil(this.activityLogs.length / this.itemsPerPageActivity);
        },
        paginatedDataActivity() {
          const start = (this.currentPageActivity - 1) * this.itemsPerPageActivity;
          const end = start + this.itemsPerPageActivity;
          return this.activityLogs.slice(start, end);
          
        },
        
        sortedAlerts() {
          return this.integrationLogs.slice().sort((a, b) => a.alertId - b.alertId);
        },
        totalPages() {
          return Math.ceil(this.integrationLogs.length / this.itemsPerPage);
        },
        paginatedData() {
          const start = (this.currentPage - 1) * this.itemsPerPage;
          const end = start + this.itemsPerPage;
          return this.integrationLogs.slice(start, end);
          
        },

      },
      methods:{
        getCredentials(){
              const params = {
                entityId: this.$route.params.entityId
              }
              const data = Object.keys(params)
                      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                      .join('&');

                      const options = {
                          method: 'POST',
                          headers: { 'content-type': 'application/x-www-form-urlencoded', 'vmiadmin-authz': localStorage.getItem("tokenId")  },
                          data,
                          url: '/ax/ceza/credentials'
                          };
                  this.axios(options)
                      .then((response) => { 
                         console.log(response.data)
                         this.merchantId = response.data.merchantId
                      }).catch((err) => { 
                        this.errors.show = true
                        this.errors.msg = err.response.data.msgText
                  })
          },
        nextPage() {
          if (this.currentPage < this.totalPages) {
            this.currentPage++;
          }
        },
        prevPage() {
          if (this.currentPage > 1) {
            this.currentPage--;
          }
        },
        nextPageActivity() {
          if (this.currentPageActivity < this.totalPagesActivity) {
            this.currentPageActivity ++;
          }
        },
        prevPageActivity() {
          if (this.currentPageActivity > 1) {
            this.currentPageActivity --;
          }
        },
        goHome(){
          window.location.href="/#/customer-search"
        },
        goBack(){
          this.$router.go(-1);
        },
        goToCompany(){
          window.location.href="/#/customer-company/" + this.$route.params.entityId
          },
        goToApplication(){
          console.log(this.show.profile.signupHost)
          if(this.show.profile.signupHost == 'fintech.ceza.org'){
            window.location.href="/#/customer-application-list/" + this.$route.params.entityId
          }
          if(this.show.profile.signupHost == 'gaming.ceza.org'){
            window.location.href="/#/customer-gaming-list/" + this.$route.params.entityId
          }
         
        },
        goToUsers(){
          window.location.href="/#/customer-users/" + this.$route.params.entityId
        },
        goToTransactions(){
          window.location.href="/#/customer-transactions/" + this.$route.params.entityId
        },
        goToProfile(){
          window.location.href="/#/customer-profile/" + this.$route.params.entityId
        },
       
         
        formatDate: function(date) {
          return moment(new Date(date)).format('MM/DD/YYYY hh:mm:ss A');
        },
         
        openResolve(alert){
          this.alert = alert
          this.showResolve = !this.showResolve
        },
          getProfile() {
            const params = {
              eid: this.$route.params.entityId
            }
            const data = Object.keys(params)
                      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                      .join('&');

            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
            data,
              url: '/ax/getCustomerProfile',
              }
              this.axios(options)
              .then((response) => {
              this.show.profile = response.data;
              this.show.corpDetails = response.data.corpDetails
               
              if(response.data.presentAddress !== undefined || response.data.presentAddress !== null){
                this.show.profile.presentAddress.numberStreet = response.data.presentAddress.numberStreet
              }
              }).catch((err) => {
                  
              })
          },
        
          getAlert(){
              const params = {
                entityId: this.$route.params.entityId
              }
              const data = Object.keys(params)
                      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                      .join('&');

                      const options = {
                          method: 'POST',
                          headers: { 'content-type': 'application/x-www-form-urlencoded', 'vmiadmin-authz': localStorage.getItem("tokenId")  },
                          data,
                          url: '/ax/getCustomerAlerts'
                          };
                  this.axios(options)
                      .then((response) => { 
                         console.log(response.data)
                         this.alertLogs = response.data
                      }).catch((err) => { 
                        this.errors.show = true
                        this.errors.msg = err.response.data.msgText
                  })
          },
          getLogsByMerchantId(){
            this.getCredentials();
              const params = {
		          merchantId: this.merchantId,
                  
		        }

                const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId")  },
                    url: '/ax/integrationlogs',
                    params
                    };

                this.axios(options)
                .then((response) => { 
                          
                   this.integrationLogs = response.data
                 

                
                })
        },
          getActivity(){
              const params = {
                eid: this.$route.params.entityId
              }
              const data = Object.keys(params)
                      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                      .join('&');

                      const options = {
                          method: 'POST',
                          headers: { 'content-type': 'application/x-www-form-urlencoded', 'vmiadmin-authz': localStorage.getItem("tokenId")  },
                          data,
                          url: '/ax/getCustomerActivityLog'
                          };
                  this.axios(options)
                      .then((response) => { 
                         console.log(response.data)
                         this.activityLogs = response.data
                      }).catch((err) => { 
                        this.errors.show = true
                        this.errors.msg = err.response.data.msgText
                  })
          },

            filterData(){
                    const params = {
                      eid: this.merchantId,
                      fromDate: moment(this.fromDate).subtract(1, 'days').format('MM/DD/yyyy'),
                      toDate: moment(this.toDate).add(1, 'days').format('MM/DD/yyyy'),
                      rowsPerPage: 50,
                      page: 1,
                      currency: this.currency,

                    }
                    const data = Object.keys(params)
                            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                            .join('&');

                      const options = {
                          method: 'POST',
                          headers: { 'content-type': 'application/x-www-form-urlencoded', 'vmiadmin-authz': localStorage.getItem("tokenId")  },
                          data,
                          url: '/ax/getFundActivitiesByPage'
                          };
                      this.axios(options)
                          .then((response) => { 
                            console.log(response.data)
                          }).catch((err) => { 
                      })
          },
        
          sessionCheck() {
            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
            
              url: '/ax/sessionCheck',
              }
              this.axios(options)
              .then((response) => {
              
              this.getProfile();
              }).catch((err) => {
                 

                  if(err.response.data.msgCode == '00001') {
                      window.location.href = "/#/login";
                  }  
              })
          },

      },
      mounted(){
          this.sessionCheck();
          this.getActivity();
          this.getAlert();
          this.getLogsByMerchantId();
      }
  }
</script>
        
<style lang=scss scoped>
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .header-wrapper img{
    width: 10em;
  }
  .main-content{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
  }
  .wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
    padding: 20px 5px;
    width: 100%;
  }
 .market-content {
      width: 100%;
      border-radius: 10px;
      padding: 0;
      max-height: 450px; /* Set your desired maximum height */
      overflow: auto;
      table {
        width: 100%;
        thead {
          th {
            font-size: 15px;
            color: #c5c2c2; 
            letter-spacing: 0.65px;
            padding: 1em;
            position: sticky;
            top: 0;
            background-image: linear-gradient(
            to bottom,
            #5a5f71 29%,
            #10121C 88%
      );
            text-align: inherit;
          }
        }
  
        tbody {
          background-image: linear-gradient(
            to bottom,
            #202538 29%,
            #10121C 88%
      );
          td {
            color: #FFFFFF;
            letter-spacing: 0.65px;
            padding: 1em;
  
            a {
              color: #FFFFFF;
              text-decoration: none;
  
              &:hover {
                text-decoration: underline;
              }  
            }
  
            &.hash {
              a {
                color: #FFBE21;
              }
              
            }
          }
        }
      }
    }
  .content-wrapper{
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center !important;
    width: 100%;
    min-width: 350px !important;
    margin: 10px auto;
    background-color: #ffffff;
    /* background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important; */
    border-radius: 10px;;
    /* z-index: 2; */
    /* max-height: calc(100vh - 110px);
    overflow-y: auto; */
    padding: 5px;
  }
  .progress-bars {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .form-group .button{
    margin-top: 100px !important;
  }
  .icon{
    width: 2rem;
  }
  .progress-bar {
    width: 100px;
    height: 10px;
    background-color: lightgray;
    margin-right: 5px;
    border-radius: 6px;
  }

  .enhanced{
    margin-top: 2em;
  }

.three-column {
  display: flex;
  justify-content: space-between;
}
.modal-footer{
  border-top: none !important;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-column {
  flex-basis: calc(33.33% - 20px);
  margin-bottom: 20px;
}
form{
  padding-top: 3px;
}

@media (max-width: 767px) {
  .form-column {
    flex-basis: 100%;
  }
}

label {
  color: white;
  margin-right: 5px;
  font-size: 15px;
}
.modal-body{
  margin-left: 15px;
}

   .btn-flag {
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    z-index: 2;
  }
   .mobile-container {
    position: relative;
   }
   .default-flag {
      width: 33px;
      margin-right: 0.25em;
    }
    

    .country-list {
      position: absolute;
      background: #ccc;
      width: 300px;
      color: #333;
      top: 70px;
      padding: 0.5em;
      height: 13em;
      overflow: auto;
      z-index: 3;
    }
      .row {
        margin: 0 !important;
      }
        .col-sm-6 {
          display: flex;
          padding: 0.5em;
          align-items: center;
          cursor: pointer;
        }
        .col-sm-6:hover {
            background: darken(#ccc, 10%);
          }
          .country-name {
            margin-left: 0.5em;
          }

          span {
            font-size: 0.9em;
          }
   

  .flag-list-img {
    width: 33px;
  }
  .btn-cancel{
        float: right;
        background-color: #333;
        color: white;
    }

  .disabled{
  cursor: not-allowed;
}
.input-group{
  flex-wrap: nowrap;
  padding: 0;
}
.input-group .form-control{
  height: auto;
}
fieldset{
  min-height: 50px !important;
}

.legend{
   color: white !important;
    padding-top: 10px;
  }
  h1 {
  text-align: center;
  font-size: 5rem;
  }

  form {
  width: 90%;
  margin: 0 auto;
  }

  .box {
  padding: 0.1rem;
  border-radius: 6px !important;
  margin-bottom: 10px;
  }

  label {
  display: flex;
  font-size: 16px;;
  }

  input, .form-control, .form-select {
  display: inline-block;
  width: 100%;
  border-radius: 10px;
  }

  input:required:invalid, input:focus:invalid {
  background-position: right top;
  background-repeat: no-repeat;
  -moz-box-shadow: none;
  }

  input:required:valid {
  background-position: right top;
  background-repeat: no-repeat;
  }

  #masthead {
    padding: 10rem 0;
    background-size: 100%;
    /*color: #fff;*/;
  }
  .help{
    margin: 2rem;
    
  }
@media all and (min-width: 35rem) and (max-width: 60rem) {
 
    .box {
    float: left;
    width: 33%;
    }
    .box2 {
    float: left;
    width: 50%;
    }

    label {
    display: flex;
    }

    #masthead {
    }
}

@media all and (min-width:60rem) {
  
    label {
    display: inline;
    width: 150px;
    float: left;
    }
    .box2 {
    float: left;
    width: 50%;
    }
    .box {
    width: 33%;
    float: left;
    }

    input {
    width: 50%;
    }

    #masthead {
    padding: 9rem;
    }
}
  
  </style>